import { apiConfig, apiDigitalRiverConfig, apiSmartChannelConfig } from 'api';
import { CONFIG } from 'config';
import type { AxiosInstance, AxiosRequestConfig, InternalAxiosRequestConfig } from 'axios';
import axios, { AxiosError } from 'axios';
import { object2UrlParams } from 'js/utils/common';
import { axiosLogger, errorIsCatchable } from 'js/utils/app';
import { isExpiredJwtToken, isUnauthorized } from 'js/utils/response';
import { refreshTokenService } from 'api/refreshTokenService';
import { noop } from 'lodash';
import { delayedPromise, isNetworkError } from 'api/utils';
import { ApiTypeEnum } from 'js/enums';

const MAX_RETRIES = 3;

export const setupInterceptors = (api: AxiosInstance) => {
	api.interceptors.request.use(
		(config: InternalAxiosRequestConfig) => {
			const { apiVersion, retryCount } = config;
			if (apiVersion !== null && !retryCount) {
				config.url = `/v${config.apiVersion || CONFIG.API_VERSION}${config.url}`;
			}

			config.paramsSerializer = {
				serialize(params) {
					return object2UrlParams(params);
				},
			};

			// JWT token
			if (config.authToken) {
				const token = refreshTokenService.resolveCurrentToken(config.authToken);
				config.headers.Authorization = `Bearer ${token}`;
			}

			return config;
		},
		(error) => Promise.reject(error),
	);

	api.interceptors.response.use(undefined, async (error: AxiosError) => {
		const { response } = error;
		const config = error.config as AxiosRequestConfig | undefined;

		// Canceled request by script
		if (axios.isCancel(error)) {
			axiosLogger.log('Request canceled', error);
			return;
		}

		// Action based on response status
		if (response?.status) {
			// Refresh token
			if (isExpiredJwtToken(error) && !config?.isExpiredTokenRetry) {
				const refetchConfig: AxiosRequestConfig = { ...config, isExpiredTokenRetry: true, apiVersion: null };
				const refreshTokenResponse = await refreshTokenService.subscribe(api, config?.authToken).catch(noop);

				if (refreshTokenResponse) {
					// Update auth token
					refetchConfig.onRefreshToken?.(refreshTokenResponse);
					refetchConfig.authToken = refreshTokenResponse.token;

					return api(refetchConfig);
				}
			}

			if (isUnauthorized(error) && config?.onNonAuthorized) {
				config.onNonAuthorized();
				axiosLogger.log('Unauthorized user', error);
				return;
			}
		}

		return Promise.reject(error);
	});

	api.interceptors.response.use(undefined, async (error: AxiosError) => {
		const config = error.config as AxiosRequestConfig | undefined;

		if (!config || config.disableRetry || !isNetworkError(error)) {
			return Promise.reject(error);
		}

		const currentRetryCount = config?.retryCount || 0;

		if (currentRetryCount < MAX_RETRIES) {
			const nextConfig = { ...config, retryCount: currentRetryCount + 1 };
			const delay = 2 ** nextConfig.retryCount * 1000;
			await delayedPromise(delay);

			return api(nextConfig);
		}

		return Promise.reject(error);
	});

	// Log uncaught errors
	api.interceptors.response.use(
		(response) => response,
		(error) => {
			axiosLogger.error(error);
			return Promise.reject(error);
		},
	);
};

export const setupDigitalRiverInterceptors = (api: AxiosInstance) => {
	api.interceptors.response.use(undefined, async (error: AxiosError) => {
		const config = error.config as AxiosRequestConfig | undefined;

		if (!config || config.disableRetry || !isNetworkError(error)) {
			return Promise.reject(error);
		}

		const currentRetryCount = config?.retryCount || 0;

		if (currentRetryCount < MAX_RETRIES) {
			const nextConfig = { ...config, retryCount: currentRetryCount + 1 };
			const delay = 2 ** nextConfig.retryCount * 1000;
			await delayedPromise(delay);

			return api(nextConfig);
		}

		return Promise.reject(error);
	});

	api.interceptors.response.use(undefined, (error: AxiosError) => {
		if (errorIsCatchable(error)) {
			console.error('[digital-river]', error, error?.response?.data);
		}
		return Promise.reject(error);
	});
};

export const createAxiosInstance = (config: AxiosRequestConfig = {}): AxiosInstance => {
	// Get config
	let defaultConfig: AxiosRequestConfig;
	switch (config.apiType) {
		case ApiTypeEnum.DR:
			defaultConfig = apiDigitalRiverConfig;
			break;
		case ApiTypeEnum.SCH:
			defaultConfig = apiSmartChannelConfig;
			break;
		case ApiTypeEnum.OMS:
		default:
			defaultConfig = apiConfig;
			break;
	}

	// Create instance
	const api = axios.create({
		...defaultConfig,
		...config,
	});

	// Add interceptors
	switch (config.apiType) {
		case ApiTypeEnum.DR:
			setupDigitalRiverInterceptors(api);
			break;
		case ApiTypeEnum.SCH:
			break;
		case ApiTypeEnum.OMS:
		default:
			setupInterceptors(api);
			break;
	}

	return api;
};
