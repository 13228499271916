import { generateEnumUtils } from 'js/enums/generator/enumFunctions';

export enum DocumentTypeEnum {
	PAYMENT_INSTRUCTIONS = 'payment_instructions',
	INVOICE = 'invoice',
	VAT_INVOICE = 'vat_invoice',
	CREDIT_MEMO = 'credit_memo',
	PAYG_PRICE_BREAKDOWN_MSP = 'payg_price_breakdown_msp',
	PAYG_REPORTS_ALL = 'payg_reports_all',
	PAYG_PRODUCT_USAGE_REPORT = 'payg_product_usage_report',
	PAYG_DISTRIBUTION_PARTNERS_USAGE_REPORT = 'payg_distribution_partners_product_usage',
	PAYG_DISTRIBUTION_PARTNERS_DAILY_USAGE_REPORT = 'payg_distribution_partners_daily_product_usage',
	PAYG_PRICE_BREAKDOWN_DISTRIBUTION_PARTNER = 'payg_price_breakdown_distribution_partner',
}

export const documentTypeEnumUtils = generateEnumUtils<DocumentTypeEnum>(DocumentTypeEnum, {
	translatePath: 'enums:documentType',
	aliases: {
		[DocumentTypeEnum.VAT_INVOICE]: DocumentTypeEnum.INVOICE,
		[DocumentTypeEnum.PAYG_PRICE_BREAKDOWN_DISTRIBUTION_PARTNER]: DocumentTypeEnum.PAYG_PRICE_BREAKDOWN_MSP,
	},
});
