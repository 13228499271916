import { useCallback } from 'react';
import { IAuthContextState, TAuthToken } from 'js/reducers/authContextReducer';
import { useAuthUserData } from 'module/security/hooks/useAuthUserData';
import { useIsRefreshRestricted } from 'js/hooks/useIsRefreshRestricted';

type TUseRefreshAuthState = (
	state: IAuthContextState | null,
	onNonAuthorized: (token?: TAuthToken | null) => void,
) => Promise<IAuthContextState | null>;

export const useRefreshAuthState = (): TUseRefreshAuthState => {
	const authUserData = useAuthUserData();
	const isRefreshRestricted = useIsRefreshRestricted();

	return useCallback(
		(state, onNonAuthorized) =>
			new Promise(async (resolve) => {
				if (!state?.token) {
					resolve(null);
					return;
				}

				// Do not run when is restricted
				if (isRefreshRestricted) {
					resolve(state);
					return;
				}

				// Update partner data
				const data = await authUserData({ token: state.token }, onNonAuthorized);
				if (data) {
					resolve({
						...state,
						...data,
					});
					return;
				}

				resolve(null);
			}),
		[authUserData, isRefreshRestricted],
	);
};
