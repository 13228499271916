import { IConfigProductGroup } from 'types/config/productGroup';
import { TransactionTypeEnum } from 'module/licenses/enums';

/** Config object */
export const PRODUCT_GROUP: IConfigProductGroup = {
	EXCEPTIONS: [
		{
			transactionTypes: [TransactionTypeEnum.NEW],
			groups: ['rcd', 'rcdu'],
			message: 'common:productGroupExceptions.premiumRemoteControlQuantityDisabled',
			variant: 'info',
		},
		{
			transactionTypes: [TransactionTypeEnum.NEW],
			groups: ['cbw', 'bcw'],
			message: 'common:productGroupExceptions.cloudBackupStorageInfo',
			variant: 'info',
		},
		{
			transactionTypes: [
				TransactionTypeEnum.INCREASE,
				TransactionTypeEnum.RENEW_AND_DECREASE,
				TransactionTypeEnum.RENEW_AND_INCREASE,
			],
			groups: ['cbw', 'bcw'],
			message: 'common:productGroupExceptions.cloudBackupStorageInfo',
			variant: 'warning',
		},
	],
	DISABLED_QUANTITY: ['rcd', 'rcdu'],
	DISALLOWED_BY_COUNTRY: [
		{
			countryCode: 'RU',
			groups: ['svd', 'asm', 'bmp', 'bup', 'dsp', 'usp'],
		},
		{
			countryCode: 'IN',
			groups: ['cpr'],
		},
		{
			countryCode: 'MM', // Myanmar
			groups: ['svd', 'asm', 'hmaa'],
		},
		{
			countryCode: 'CN', // China
			groups: ['svd', 'asm', 'hmaa'],
		},
		{
			countryCode: 'IQ', // Iraq
			groups: ['svd', 'asm', 'hmaa'],
		},
		{
			countryCode: 'OM', // Oman
			groups: ['svd', 'asm', 'hmaa'],
		},
		{
			countryCode: 'TM', // Turkmenistan
			groups: ['svd', 'asm', 'hmaa'],
		},
		{
			countryCode: 'UG', // Uganda
			groups: ['svd', 'asm', 'hmaa'],
		},
	],
	COMPARATIVE: {
		DEFAULT_PRICE_LIST: 'PREPAID_OMS',
		RULES: [
			{
				from: ['bms', 'bus'],
				to: ['ssp', 'dsp', 'usp'],
				toEquivalent: 'ssp',
				message: 'submodules:comparativeFeatures.message',
			},
			{
				from: ['bmg', 'bug'],
				to: ['dsp', 'usp'],
				toEquivalent: 'dsp',
				message: 'submodules:comparativeFeatures.message',
			},
			{
				from: ['bmp', 'bup'],
				to: ['usp'],
				toEquivalent: 'usp',
				message: 'submodules:comparativeFeatures.message',
			},
		],
		LIST: [
			{
				groups: ['bms', 'bus'],
				name: 'Antivirus',
				features: ['ONLINE_MANAGEMENT', 'DEVICE_PROTECTION', 'DATA_PROTECTION', 'IT_SUPPORT'],
			},
			{
				groups: ['ssp'],
				name: 'Essential <br/>Business Security',
				features: ['ONLINE_MANAGEMENT', 'DEVICE_PROTECTION', 'DATA_PROTECTION', 'IT_SUPPORT', 'IDENTITY_PROTECTION'],
			},
			{
				groups: ['bmg', 'bug'],
				name: 'Antivirus <br/>Pro',
				features: ['ONLINE_MANAGEMENT', 'DEVICE_PROTECTION', 'DATA_PROTECTION', 'IT_SUPPORT'],
			},
			{
				groups: ['dsp'],
				name: 'Premium <br/>Business Security',
				features: [
					'ONLINE_MANAGEMENT',
					'DEVICE_PROTECTION',
					'DATA_PROTECTION',
					'IT_SUPPORT',
					'IDENTITY_PROTECTION',
					'UNLIMITED_VPN',
					'USB_PROTECTION',
				],
			},
			{
				groups: ['bmp', 'bup'],
				name: 'Antivirus <br/>Pro Plus',
				features: [
					'ONLINE_MANAGEMENT',
					'DEVICE_PROTECTION',
					'DATA_PROTECTION',
					'IT_SUPPORT',
					'IDENTITY_PROTECTION',
					'UNLIMITED_VPN',
				],
			},
			{
				groups: ['usp'],
				name: 'Ultimate <br/>Business Security',
				features: [
					'ONLINE_MANAGEMENT',
					'DEVICE_PROTECTION',
					'DATA_PROTECTION',
					'IT_SUPPORT',
					'IDENTITY_PROTECTION',
					'UNLIMITED_VPN',
					'USB_PROTECTION',
					'PATCH_MANAGEMENT',
				],
			},
		],
		WINDOWS_ONLY: ['USB_PROTECTION', 'PATCH_MANAGEMENT', 'UNLIMITED_VPN', 'IDENTITY_PROTECTION'],
		AVAILABLE_ADDON: ['PATCH_MANAGEMENT'],
	},
};
