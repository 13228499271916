import { IEntityLegacyPaymentOption } from 'module/account';
import { CreditCardBrandEnum, CreditCardLegacyBrandEnum } from 'module/account/enums';

export const legacyPaymentOptionNormalizer = {
	normalizeList(
		list?: IEntityLegacyPaymentOption<CreditCardLegacyBrandEnum>[],
	): IEntityLegacyPaymentOption<CreditCardBrandEnum>[] {
		return list?.map((item) => this.normalize(item)) || [];
	},

	normalize(
		item: IEntityLegacyPaymentOption<CreditCardLegacyBrandEnum>,
	): IEntityLegacyPaymentOption<CreditCardBrandEnum> {
		return {
			...item,
			creditCard: {
				...item.creditCard,
				brand: this.normalizeLegacyBrand(item.creditCard.brand),
			},
		};
	},

	normalizeLegacyBrand(brand: CreditCardLegacyBrandEnum): CreditCardBrandEnum {
		switch (brand) {
			case CreditCardLegacyBrandEnum.VISA:
				return CreditCardBrandEnum.VISA;
			case CreditCardLegacyBrandEnum.MASTER_CARD:
				return CreditCardBrandEnum.MASTER_CARD;
			case CreditCardLegacyBrandEnum.AMERICAN_EXPRESS:
				return CreditCardBrandEnum.AMERICAN_EXPRESS;
			case CreditCardLegacyBrandEnum.DISCOVER:
				return CreditCardBrandEnum.DISCOVER;
			case CreditCardLegacyBrandEnum.JCB:
				return CreditCardBrandEnum.JCB;
			case CreditCardLegacyBrandEnum.MAESTRO:
				return CreditCardBrandEnum.MAESTRO;
			case CreditCardLegacyBrandEnum.UNION_PAY:
				return CreditCardBrandEnum.UNION_PAY;
			default:
				throw Error(`Not supported credit card brand: ${brand}`);
		}
	},
};
