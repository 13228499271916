import { useCallback } from 'react';
import { IJWTData } from 'module/security';
import { IAuthDataUser, IAuthTokenResponse, TAuthToken } from 'js/reducers/authContextReducer';
import { jwtDecode } from 'jwt-decode';
import { getApiPartnerDetailPromise } from 'module/partners/api/apiPartners';
import { getPartnerRolesAndRoleGroups } from 'module/partners/utils/roles';
import { apiPartnerPriceListsPromise } from 'js/priceList/apiPartnerPriceLists';
import { TPartnerPriceLists } from 'js/priceList';
import { IUserLocale } from 'types/config';
import { resolveUserLocaleFromLocaleCode } from 'i18n/utils';
import { getAffiliateConfig_LocaleFormat } from 'module/partners/utils/affiliateConfig';
import { isUnauthorized } from 'js/utils/response';
import { isAxiosError } from 'axios';

type TUseAuthUserData = (
	response: IAuthTokenResponse,
	onNonAuthorized?: (token?: TAuthToken | null) => void,
) => Promise<IAuthDataUser | null>;

export const useAuthUserData = (): TUseAuthUserData => {
	return useCallback(async (response, onNonAuthorized) => {
		const data = jwtDecode<IJWTData>(response.token);

		// Get partner
		try {
			const partnerResponse = await getApiPartnerDetailPromise(data.affiliateId, {
				authToken: response.token,
				onRefreshToken(r) {
					response = r;
				},
			});
			if (!partnerResponse) {
				return null;
			}

			// Extract response
			const { data: partner } = partnerResponse;
			let company = null;

			// If he has a parent company => get the company detail
			if (partner?.parentCompanyId) {
				const companyResponse = await getApiPartnerDetailPromise(partner.parentCompanyId, {
					authToken: response.token,
					onRefreshToken(r) {
						response = r;
					},
				});
				if (!companyResponse) {
					return null;
				}
				company = companyResponse.data;
			}

			// For partners get price lists
			const roles = getPartnerRolesAndRoleGroups(company || partner);
			let priceLists: TPartnerPriceLists | null = null;
			if (roles.isGroupPartner) {
				const priceListsResponse = await apiPartnerPriceListsPromise(company?.id || partner.id, {
					authToken: response.token,
					onRefreshToken(r) {
						response = r;
					},
				});

				if (!priceListsResponse) {
					return null;
				}
				priceLists = priceListsResponse;
			}

			// Get user locale
			const locale: IUserLocale = resolveUserLocaleFromLocaleCode(partner.locale);
			const localeResponse = await getAffiliateConfig_LocaleFormat({
				affiliateId: partner.id,
				defaultValue: locale.format,
				apiConfig: {
					catchError: false,
					authToken: response.token,
					onRefreshToken(r) {
						response = r;
					},
				},
			});
			if (localeResponse) {
				locale.format = localeResponse;
			}

			return { partner, company, priceLists, locale, ...response };
		} catch (e) {
			if (isAxiosError(e) && isUnauthorized(e)) {
				onNonAuthorized?.(response.token);
			}
			return null;
		}
	}, []);
};
