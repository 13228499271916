import type { AxiosInstance, AxiosRequestConfig } from 'axios';
import { createAxiosInstance } from 'api/setupInterceptors';
import { useMemo } from 'react';
import { useAuthContext } from 'js/contexts';
import { useOnNonAuthorizedCallback } from 'js/hooks/useOnNonAuthorizedCallback';

export type TUseApiProps = {
	config?: AxiosRequestConfig;
};

export const useApi = (props?: TUseApiProps): AxiosInstance => {
	const { params, ...config } = props?.config || {};
	const {
		setAuthToken,
		authState: { token },
	} = useAuthContext();
	const onNonAuthorized = useOnNonAuthorizedCallback();

	return useMemo(() => {
		return createAxiosInstance({
			onNonAuthorized() {
				onNonAuthorized(token);
			},
			onRefreshToken(response) {
				setAuthToken(response);
			},
			authToken: token,
			...config,
		});
		// "config" is not memoized, do not put into deps
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [token, setAuthToken, onNonAuthorized]);
};
