import { useMemo } from 'react';
import { TPriceListCode } from 'js/priceList';
import { useApiProductGroupList } from 'module/purchase/hooks/useApiPurchase';
import { TApiListResponse } from 'types/api';
import { IEntityProductGroup, TEntityProductGroupListFilter } from 'module/purchase';
import { logError } from 'js/utils/app';
import { CONFIG } from 'config';
import { useOrderContext } from 'js/contexts';
import { isDefined } from 'js/utils/common';

export type TUseSelectProductGroups = ReturnType<typeof useApiProductGroupList>;

/**
 * Get list of disallowed product groups codes by order partner
 * @returns {string[] | null}
 *
 * TODO: Remove when https://butr.avast.com/browse/BOSS-8418
 * @deprecated Remove when BOSS-8418
 */
const useDisallowedProductGroupCodes = (): string[] | null => {
	const {
		orderState: { partner },
	} = useOrderContext();

	return useMemo(() => {
		const countryCode = partner?.countryCode;
		const disallowedGroups = CONFIG.PRODUCT_GROUP.DISALLOWED_BY_COUNTRY.find(
			(item) => item.countryCode === countryCode,
		);
		return disallowedGroups?.groups || null;
	}, [partner]);
};

const useProductGroupsFilter = (priceListCode: TPriceListCode): TEntityProductGroupListFilter | undefined => {
	const {
		orderState: { partner, customerCurrencyCode, customer },
		isEndCustomerBillableParty,
	} = useOrderContext();

	// Partner
	if (partner?.id) {
		return { priceListCode, partnerId: partner.id };
	}

	// End customer
	if (isEndCustomerBillableParty && customerCurrencyCode && customer?.billing?.countryCode) {
		return {
			priceListCode,
			currencyCode: customerCurrencyCode,
			countryCode: customer.billing.countryCode,
		};
	}

	logError('Unable to determinate product list filter attributes');
	return undefined;
};

const useSelectProductGroupList = (priceListCode: TPriceListCode) => {
	const disallowedGroupCodes = useDisallowedProductGroupCodes();
	const filter = useProductGroupsFilter(priceListCode);
	const { data: productGroups, query } = useApiProductGroupList({
		filter,
		queryConfig: { enabled: isDefined(filter) },
	});

	return {
		productGroups: useMemo(
			(): TApiListResponse<IEntityProductGroup> =>
				productGroups.filter(
					(group) =>
						// TODO: Refactor when https://butr.avast.com/browse/BOSS-3731
						!disallowedGroupCodes?.includes(group.code),
				),
			[productGroups, disallowedGroupCodes],
		),
		query,
	};
};

/**
 * Get product groups
 * @param {TPriceListCode} priceListCode
 * @returns {TUseSelectProductGroups}
 */
export const useSelectProductsGroups = (priceListCode: TPriceListCode): TUseSelectProductGroups => {
	const { orderMarketSegments } = useOrderContext();
	const { productGroups: data, query } = useSelectProductGroupList(priceListCode);
	const productGroups = useMemo(
		() => data.filter((group) => orderMarketSegments.includes(group.marketSegment)),
		[data, orderMarketSegments],
	);

	return { data: productGroups, query };
};
