import React, { ReactElement } from 'react';
import { FormMessage, InputNumber } from '@avast/react-ui-components';
import { useTranslation } from 'react-i18next';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import { numberFormatter } from 'js/utils/number';
import { CustomTable } from 'js/components/molecules/CustomTable';
import Skeleton from 'react-loading-skeleton';
import { usePartyPriceTranslation } from 'module/purchase/hooks/usePartyPriceTranslation';
import { getProductValidityLabels } from 'module/purchase/utils/common';
import {
	useLicenseOperationUnitTotal,
	useLicenseOperationVariantsPricing,
} from 'module/licenses/hooks/licenseOperation';
import { FormikControl } from 'js/components/formik/FormikControl';
import { isGroupBusiness, sortProducts } from 'module/purchase/utils/selectors';
import { useFormikContext } from 'formik';
import { FixedWidthWrapper } from 'js/components/molecules/Inputs/FixedWidthWrapper';
import { IEntityProductGroup } from 'module/purchase';
import { LICENSE_OPERATION_QUANTITY } from 'module/licenses/contants';
import { ILicenseOperationForm } from 'module/licenses';
import { useOrderContext } from 'js/contexts';

type TLicenseOperationVariantTableProps = {
	productGroup: IEntityProductGroup;
};

export const VariantTable = (props: TLicenseOperationVariantTableProps): ReactElement | null => {
	const { productGroup } = props;
	const {
		values: { licenseBillableParty, licenseOperation },
	} = useFormikContext<ILicenseOperationForm>();
	const [t] = useTranslation(purchaseConfig.trNamespace);
	const { tPartyPrice } = usePartyPriceTranslation();
	const { isEndCustomerBillableParty } = useOrderContext();
	const { pricing, isPricingLoading } = useLicenseOperationVariantsPricing(productGroup);
	const unitTotal = useLicenseOperationUnitTotal();
	const isBusiness = isGroupBusiness(productGroup);

	return (
		<>
			<CustomTable borderless>
				<thead>
					<tr>
						<CustomTable.Th />
						<CustomTable.Th
							align="center"
							description={t('tableVariant.tooltip.quantity')}
						>
							{t('common:entity.quantity')}
						</CustomTable.Th>
						<CustomTable.Th
							align="center"
							description={t(`tableVariant.tooltip.unitType.${productGroup.unitType}`)}
						>
							{t(`common:entity.unitType.${productGroup.unitType}`, { count: 0 })}
						</CustomTable.Th>
						<CustomTable.Th align="end">{tPartyPrice('totalPrice', 'customer')}</CustomTable.Th>
						{!isEndCustomerBillableParty && <CustomTable.Th align="end">{tPartyPrice('totalPrice')}</CustomTable.Th>}
					</tr>
				</thead>
				<tbody>
					{productGroup.products.sort(sortProducts).map((product, i) => {
						const pricingItem = pricing?.lineitems.find((item) => item.reference_lineitem_id === product.sku);
						const unit = isBusiness ? unitTotal : product.bulkQuantity;
						const labels = getProductValidityLabels(product);

						return (
							<tr key={`${licenseOperation}-${product.sku}`}>
								<CustomTable.Td>
									<FormikControl
										groupProps={{ className: 'mb-0' }}
										idSuffix={product.sku}
									>
										<FormikControl.Radio
											testId={product.sku}
											name="variant"
											value={product.sku}
											label={labels.label}
											description={labels.description}
										/>
									</FormikControl>
								</CustomTable.Td>
								<CustomTable.Td align="center">
									<FixedWidthWrapper width={120}>
										<InputNumber
											name={`quantity-${i}`}
											value={LICENSE_OPERATION_QUANTITY}
											size="sm"
											disabled
											hideControls
										/>
									</FixedWidthWrapper>
								</CustomTable.Td>
								<CustomTable.Td align="center">
									<FixedWidthWrapper width={120}>
										<InputNumber
											name={`unit-${i}`}
											value={unit}
											size="sm"
											disabled
											hideControls
										/>
									</FixedWidthWrapper>
								</CustomTable.Td>
								<CustomTable.Td align="right">
									{isPricingLoading ? (
										<Skeleton width={100} />
									) : (
										`${numberFormatter.currency(
											pricingItem?.customerPrice?.total || 0,
											licenseBillableParty?.currencyCode,
										)}*`
									)}
								</CustomTable.Td>
								{!isEndCustomerBillableParty && (
									<CustomTable.Td align="right">
										{isPricingLoading ? (
											<Skeleton width={100} />
										) : (
											`${numberFormatter.currency(
												pricingItem?.linePriceWOTax || 0,
												licenseBillableParty?.currencyCode,
											)}*`
										)}
									</CustomTable.Td>
								)}
							</tr>
						);
					})}
				</tbody>
			</CustomTable>
			<div className="text-end mt-3">
				<FormMessage type="info">* {t('tableVariant.priceNote')}</FormMessage>
			</div>
		</>
	);
};
